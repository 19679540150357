// extracted by mini-css-extract-plugin
export var button = "post-module--button--KhirD";
export var container = "post-module--container--e01vr";
export var content = "post-module--content--tnGN8";
export var date = "post-module--date--AkJ5B";
export var excerpt = "post-module--excerpt--wPSWj";
export var icon = "post-module--icon---N5eA";
export var link = "post-module--link--T97u1";
export var post = "post-module--post--SDz4L";
export var recentPost = "post-module--recentPost--QeXy6";
export var recentPostsTitle = "post-module--recentPostsTitle--6Dj1e";
export var sideBar = "post-module--sideBar--pfxxW";
export var social = "post-module--social--Gn9+k";
export var socialsTitle = "post-module--socialsTitle--cMfJF";
export var thumbnail = "post-module--thumbnail--CN2ob";
export var title = "post-module--title--r2pLz";