import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Header from 'src/components/header/Header';
import OverviewMinimal from 'src/components/overviewMinimal/OverviewMinimal';
import MarkdownRenderer from 'src/components/markdownRenderer/MarkdownRenderer';
import ReferralAd from 'src/components/referralAd/ReferralAd';
import allCategory from 'src/helpers/allCategory';
import { getPostDate } from 'src/helpers/post';
import socials from 'src/helpers/socials';
import { fromStrapiToUI } from 'src/helpers/strapiLocales';
import Category from 'src/interfaces/Category';
import PostData from 'src/interfaces/PostData';
import languageData from 'src/languages';
import removeMd from 'remove-markdown';
import MetaTags, { PageMetaData } from 'src/components/MetaTags';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import ShareButtons from 'src/components/shareButtons/ShareButtons';
import { Result } from 'src/components/search/SearchResults';
import * as style from './post.module.scss';

interface Props {
	data: {
		postData: Partial<PostData>;
		recentPosts: {
			recentPosts: Partial<PostData>[];
		};
		postLocalizations: {
			postLocalizations: {
				locale: string;
				path: string;
			}[];
		};
		allStrapiCategories: {
			availableCategories: Category[];
		};
		allResults: {
			allResults: Result[];
		};
	};
	pageContext: {
		currentCategory: Category;
	};
}

const PostTemplate: React.FC<Props> = ({ data, pageContext }) => {
	const { locale } = useIntl();
	const { postData } = data;
	const { recentPosts } = data.recentPosts;
	const { allResults } = data.allResults;
	const { currentCategory } = pageContext;
	const [query, setQuery] = useState<string>('');

	const plainExcerpt = postData.excerpt ? removeMd(postData.excerpt) : '';

	const metaData: PageMetaData = {
		title: postData.meta?.customTitle || `${postData.title} | SpectroCoin Blog`,
		description: postData.meta?.description || plainExcerpt,
		keywords: postData.meta?.keywords,
		og: {
			title:
				postData.meta?.openGraph?.customTitle ||
				postData.meta?.customTitle ||
				`${postData.title} | SpectroCoin Blog`,
			description: postData.meta?.openGraph?.customDescription || postData.meta?.description || plainExcerpt,
			image: postData.meta?.openGraph?.image?.localFile.publicURL || postData.thumbnail?.localFile.publicURL,
			urlPathname: postData.path !== undefined ? getLocalizedRoute(postData.path, locale) : undefined,
		},
	};

	const { availableCategories } = data.allStrapiCategories;
	const { postLocalizations } = data.postLocalizations;
	const allAvailableCategories = availableCategories.slice();
	allAvailableCategories.unshift(allCategory(locale));

	let thumbnail;
	if (postData.thumbnail) thumbnail = getImage(postData.thumbnail.localFile);

	const navigationRoutes: { locale: string; route: string }[] = [];
	Object.keys(languageData).forEach((key) => {
		const language = languageData[key];
		if (!language) return;

		const postLocalization = postLocalizations.find((p) => fromStrapiToUI(p.locale) === language.locale);
		if (postLocalization) {
			// Found a post localization for this language
			navigationRoutes.push({
				locale: language.locale,
				route: postLocalization.path,
			});
		} else {
			// Didn't find a post localization for this language, direct to home page
			navigationRoutes.push({
				locale: language.locale,
				route: '',
			});
		}
	});

	return (
		<>
			<MetaTags data={metaData} />
			<Header
				categories={allAvailableCategories}
				currentCategory={currentCategory}
				navigationRoutes={navigationRoutes}
				allResults={allResults}
				setQuery={setQuery}
				query={query}
			/>
			<div className={style.container}>
				<div className={style.post}>
					<h1 className={style.title}>{postData.title}</h1>
					<div className={style.date}>{getPostDate(postData.date || '', locale)}</div>
					{postData && (
						<MarkdownRenderer contentImages={postData.childrenFile} className={style.excerpt}>
							{postData.excerpt}
						</MarkdownRenderer>
					)}
					{thumbnail && (
						<GatsbyImage image={thumbnail} alt={postData.thumbnailAlt || ''} className={style.thumbnail} />
					)}
					<MarkdownRenderer contentImages={postData.childrenFile} className={style.content}>
						{postData.content}
					</MarkdownRenderer>
					<ShareButtons
						linkToPost={`${process.env.GATSBY_BASE_URL}${getLocalizedRoute(postData.path || '', locale)}`}
					/>
				</div>
				<div className={style.sideBar}>
					<ReferralAd />
					<div className={style.recentPostsTitle}>
						<FormattedMessage id="post.recentPosts" defaultMessage="Recent Posts" />
					</div>
					{recentPosts.map((post) => (
						<OverviewMinimal {...post} key={post.strapiId} className={style.recentPost} />
					))}
					<div className={style.socialsTitle}>
						<FormattedMessage id="post.socials" defaultMessage="For more updates follow us on" />
					</div>
					{socials.map((social) => (
						<div key={social.link} className={style.social}>
							<a href={social.link} target="_blank" rel="noreferrer">
								<FormattedMessage {...social.alt}>
									{(alt: string) => <img src={social.image} alt={alt} />}
								</FormattedMessage>
								{social.tag}
							</a>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export const query = graphql`
	query ($strapiId: Int!, $strapiLocale: String!, $recentPostCount: Int!, $postLocalizationIDs: [Int]!) {
		postData: strapiPosts(strapiId: { eq: $strapiId }) {
			title
			date
			path
			excerpt
			thumbnail {
				localFile {
					childImageSharp {
						gatsbyImageData
					}
					publicURL
				}
			}
			thumbnailAlt
			content
			childrenFile {
				childImageSharp {
					gatsbyImageData
				}
				url
				publicURL
			}
			meta {
				customTitle
				description
				keywords
				openGraph {
					customDescription
					customTitle
					image {
						localFile {
							publicURL
						}
					}
				}
			}
		}
		recentPosts: allStrapiPosts(
			filter: { locale: { eq: $strapiLocale }, strapiId: { ne: $strapiId } }
			sort: { fields: date, order: DESC }
			limit: $recentPostCount
		) {
			recentPosts: nodes {
				category {
					name
				}
				title
				date
				path
			}
		}
		allResults: allStrapiPosts(
			filter: { locale: { eq: $strapiLocale }, strapiId: { ne: $strapiId } }
			sort: { fields: date, order: DESC }
		) {
			allResults: nodes {
				title
				content
				path
				excerpt
			}
		}
		postLocalizations: allStrapiPosts(filter: { strapiId: { in: $postLocalizationIDs } }) {
			postLocalizations: nodes {
				locale
				path
			}
		}
		allStrapiCategories(
			filter: { locale: { eq: $strapiLocale }, posts: { elemMatch: { locale: { eq: $strapiLocale } } } }
		) {
			availableCategories: nodes {
				name
				path
				locale
			}
		}
	}
`;

export default PostTemplate;
