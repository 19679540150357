import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import socials, { Social } from 'src/helpers/socials';
import * as style from './ShareButtons.module.scss';

interface Props {
	linkToPost: string;
}

interface ShareData {
	platform: Social;
	link: string;
	text: string;
}

const translations = defineMessages({
	share: {
		id: 'post.share',
		defaultMessage: 'SHARE',
	},
	tweet: {
		id: 'post.tweet',
		defaultMessage: 'TWEET',
	},
});

const ShareButtons: React.FC<Props> = ({ linkToPost }) => {
	const { formatMessage } = useIntl();

	const shareData: ShareData[] = [
		{
			platform: socials.find((s) => s.name === 'facebook') || socials[0],
			link: `https://www.facebook.com/sharer/sharer.php?u=${linkToPost}`,
			text: formatMessage(translations.share),
		},
		{
			platform: socials.find((s) => s.name === 'linkedin') || socials[0],
			link: `https://www.linkedin.com/sharing/share-offsite/?url=${linkToPost}`,
			text: formatMessage(translations.share),
		},
		{
			platform: socials.find((s) => s.name === 'twitter') || socials[0],
			link: `https://twitter.com/intent/tweet?url=${linkToPost}`,
			text: formatMessage(translations.tweet),
		},
	];
	return (
		<div className={style.container}>
			{shareData.map((data) => {
				return (
					<a
						href={data.link}
						target="_blank"
						rel="noopener noreferrer"
						key={data.platform.name}
						className={style.shareButton}
					>
						<img
							src={data.platform.imageWhite || data.platform.image}
							alt={formatMessage(data.platform.alt)}
						/>
						{data.text}
					</a>
				);
			})}
		</div>
	);
};

export default ShareButtons;
