import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import refer from 'src/assets/images/vector/refer.svg';
import fromUItoSpectroCoinLanding from 'src/helpers/spectroCoinLandingLocales';
import * as style from './ReferralAd.module.scss';

const ReferralAd: React.FC = () => {
	const { locale } = useIntl();

	return (
		<div className={style.container}>
			<div>
				<FormattedMessage id="ad.referral.alt" defaultMessage="Invite friends">
					{(alt: string) => <img src={refer} alt={alt} className={style.image} />}
				</FormattedMessage>
			</div>
			<div className={style.textContainer}>
				<div className={style.text}>
					<FormattedMessage id="ad.referral" defaultMessage="Refer a friend, earn money" />
				</div>
				<a
					href={`https://spectrocoin.com/${fromUItoSpectroCoinLanding(locale)}/earn-bitcoins.html`}
					className={style.linkButton}
				>
					<FormattedMessage id="button.refer" defaultMessage="Go to referrals" />
				</a>
			</div>
		</div>
	);
};

export default ReferralAd;
