import { Link } from 'gatsby';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LinkWithArrow from 'src/components/linkWithArrow/LinkWithArrow';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import { getPostDate } from 'src/helpers/post';
import OverviewData from 'src/interfaces/OverviewData';
import * as style from './OverviewMinimal.module.scss';

interface Props extends Partial<OverviewData> {
	className?: string;
}

const OverviewMinimal: React.FC<Props> = ({ category, title, date, path, className }) => {
	const { locale } = useIntl();

	return (
		<div className={`${style.container} ${className}`}>
			<a className={style.category}>{category?.name.toUpperCase()}</a>
			<div className={style.title}>
				{path && (
					<Link className={style.gatsbyLink} to={getLocalizedRoute(path, locale)}>
						{title}
					</Link>
				)}
			</div>
			<div className={style.linkDateContainer}>
				{path && (
					<LinkWithArrow
						href={getLocalizedRoute(path, locale)}
						text={<FormattedMessage id="link.continueReading" defaultMessage="Continue reading" />}
					/>
				)}
				{date && <div>{getPostDate(date, locale)}</div>}
			</div>
		</div>
	);
};

export default OverviewMinimal;
